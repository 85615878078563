<template>
  <b-card>
    <div class="d-flex align-items-center justify-content-between table-header">
      <h2 class="text-primary mb-1">
        {{ typeof $route.meta.pageTitle === 'function' ? $route.meta.pageTitle() : $route.meta.pageTitle }}
      </h2>
      <div class="d-flex align-items-center">
        <b-button class="ml-1" variant="dark" @click.prevent="$router.push({ name: 'event' })">
          <feather-icon icon="ListIcon" />
          <span class="d-none d-md-inline">Back To List</span>
        </b-button>
      </div>
    </div>

    <vue-form-json-schema v-model="modelData" :schema="schema" :ui-schema="uiSchema" />

    <b-row>
      <b-col md="12">
        <validation-provider #default="{ errors }" name="harga charge" rules="required">
          <b-form-group label="Harga Charge (Rp)" label-for="charge">
            <cleave id="charge" v-model="modelData.charge" class="form-control" :raw="false"
              :options="options.number" placeholder="Masukkan harga charge" />
          </b-form-group>
          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </b-col>

      <b-col md="12">
        <validation-provider #default="{ errors }" name="minimal order" rules="required">
          <b-form-group label="Minimal Order (Pax)" label-for="minimalorder">
            <cleave id="minimalorder" v-model="modelData.minimalorder" class="form-control" :raw="false"
              :options="options.number" placeholder="Masukkan harga minimal order" />
          </b-form-group>
          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </b-col>
    </b-row>

    <b-button class="d-flex align-items-center mt-50" variant="success" @click.prevent="handleSubmit">
      <feather-icon class="mr-50" icon="SaveIcon" />
      Save
    </b-button>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import uiSchema from './schema/FormRequestUiSchema.json'
import schema from './schema/FormRequestSchema.json'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    ToastificationContent,
    Cleave,
  },
  data() {
    return {
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        }
      },
      modelData: {
        keterangan: '',
        tgl_awal: '',
        tgl_akhir: '',
        charge: '',
        minimalorder: '',
      },
      idEvent: 0,
      schema,
      uiSchema,
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.getDetailData()
    }
  },
  methods: {
    handleSubmit() {
      if (this.$route.params.id) {
        this.handleEdit()
      } else {
        this.handleCreate()
      }
    },

    handleCreate() {
      if (!this.validateForm()) {
        return
      }

      const data = {
        keterangan: this.modelData.keterangan,
        tgl_awal: this.modelData.tgl_awal,
        tgl_akhir: this.modelData.tgl_akhir,
        charge: parseInt(this.modelData.charge.replace(/,/g, '')),
        minimalorder: parseInt(this.modelData.minimalorder.replace(/,/g, '')),
      }
      this.$http.post('/api/event/create', data).then(res => {
        if (!res.data.status) {
          this.$toast({
            component: ToastificationContent, position: 'top-right',
            props: {
              title: 'Notification',
              icon: 'SlashIcon',
              variant: 'danger',
              text: res.data.message,
            },
          });
          return
        }

        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'CheckIcon',
            variant: 'success',
            text: '👋Berhasil menyimpan data',
          },
        });

        setTimeout(() => {
          this.$router.push('/event')
        }, 1000)
      }).catch(e => {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'SlashIcon',
            variant: 'danger',
            text: 'Gagal menyimpan data' + e,
          },
        });
      })
    },

    handleEdit() {
      if (!this.validateForm()) {
        return
      }
      const data = {
        kodeevent: this.data.idEvent,
        keterangan: this.modelData.keterangan,
        tgl_awal: this.modelData.tgl_awal,
        tgl_akhir: this.modelData.tgl_akhir,
        charge: parseInt(this.modelData.charge.replace(/,/g, '')),
        minimalorder: parseInt(this.modelData.minimalorder.replace(/,/g, '')),
      }
      this.$http.post('/api/event/edit', data).then(res => {
        if (!res.data.status) {
          this.$toast({
            component: ToastificationContent, position: 'top-right',
            props: {
              title: 'Notification',
              icon: 'SlashIcon',
              variant: 'danger',
              text: res.data.message,
            },
          });
          return
        }

        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'CheckIcon',
            variant: 'success',
            text: '👋Berhasil menyimpan data',
          },
        });

        setTimeout(() => {
          this.$router.push('/event')
        }, 1000)
      }).catch(e => {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'SlashIcon',
            variant: 'danger',
            text: 'Gagal menyimpan data' + e,
          },
        });
      })
    },

    getDetailData() {
      this.$http.get(`/api/event/detail/${this.$route.params.id}`).then(res => {
        const response = res.data.data
        this.data = { idEvent: response.kodeevent }
        this.modelData = {
          keterangan: response.keterangan,
          tgl_awal: response.tgl_awal,
          tgl_akhir: response.tgl_akhir,
          charge: response.charge,
          minimalorder: response.minimalorder,
        }
      }).catch(e => {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'SlashIcon',
            variant: 'danger',
            text: 'Gagal mendapatkan data' + e,
          },
        });
      })
    },

    validateForm() {
      if (this.modelData.jenisacara === '' || this.modelData.jenisacara === null) {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Silakan mengisi nama makanan',
          },
        });
        return false;
      }

      return true
    }
  }
}
</script>
